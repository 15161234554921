

import './DetailsDashboard.css';
import { ACCProjectResponse } from '../../models/ACCProjects.model'
import React, { useState, useEffect, useRef } from 'react';
import ModelsHelper from './ModelsHelper'
import Progress from './Progress'
import ClipLoader from "react-spinners/ClipLoader";
import { elements, Levels, RevitModel } from '../../models/RevitModels.model';
import { Link } from 'react-router-dom';


type inpParams = { project: ACCProjectResponse, token: string }
export default function ModelsProvided({ project, token }: inpParams) {


  const [models, setModels] = useState<Array<RevitModel>>([]);
  const [modelsLoading, setModelsLoading] = useState<Array<RevitModel>>([]);
  const [modelLoadError, setmodelLoadError] = useState("");

  const [possibleVersions, setPossibleVersions] = useState(['2022', '2023', '2024', '2025'])

  const [ArchLevels, setArchLevels] = useState<Array<Levels>>([]);
  const [ElecLevels, setElecLevels] = useState<Array<Levels>>([]);
  const [MechLevels, setMechLevels] = useState<Array<Levels>>([]);
  const [StructLevels, setStructLevels] = useState<Array<Levels>>([]);

  const [ArchModel,setArchModel] = useState<RevitModel>();


  const [modelsCreated, setCreated] = useState({ Mech: false, Elec: false, Struct: false });
  const [showModal, setShowModal] = useState(false);
  const [enableButton, setEnableButton] = useState(true);


  const [MechCheck, setMechCheck] = useState(false);
  const [ElecCheck, setElecCheck] = useState(false);
  const [StructCheck, setStructCheck] = useState(false);
  const [version, setversion] = useState('2024');




  let helper = new ModelsHelper();

  const creationRequested = () => {
    setEnableButton(true)
    setShowModal(false);


    const pattern = /\d{3,8}[-]\d{2,5}/g
    const matches = project.name.match(pattern)

    let project_number: string = project.name
    let project_name: string;
    if (matches && matches.length > 0 && project.name && project.name.match(pattern) != null) {
      project_number = matches[0]

    }

    try {
      project_name = project.name.split("- ")[1];
    } catch (e) {
      project_name = project.name
    }

    helper.createModels(MechCheck, ElecCheck, StructCheck, token, project.id, version, project_number, project_name).then((resultsOfCreation) => {
      console.log(resultsOfCreation)
      let initProgress = helper.initializeProgress(MechCheck, ElecCheck, StructCheck, version, project_number, resultsOfCreation.id)
      setModelsLoading(initProgress)
      setInterval(iterateThrougModels, 3000)
      setEnableButton(false)

    })
  }

  const iterateThrougModels = async () => {

    let result = await helper.CheckModels(project.name, token)
    if (result) { window.location.reload(); }

    setModelsLoading(helper.ModelsLoading)



  }

function getPECmdoels(model:RevitModel){
  if (model.parentFolder.name.includes("Struct")) { return StructLevels }
  if (model.parentFolder.name.includes("Mech")) { return MechLevels }
  if (model.parentFolder.name.includes("Elec")) { return ElecLevels }
}

async function WorkshareModel(mod: RevitModel){

  helper.createFolderWS(token,project.id)

  if(possibleVersions.length > 1){
    alert("Please add a revit model first! This will determine the version of Revit to make this project dependant on.")
    return
  }
  let initItem = await helper.workshareModel(mod,token,project.id,possibleVersions[0]);
  
  mod.workshareItem = initItem
  setWorkItemWorkshare(mod,initItem,"Worksharing... ")

  //TODO Item interval to check status

  setInterval(async () =>{checkWorkshareStatus(mod)}, 30000)

  
}

async function checkWorkshareStatus(mod:RevitModel) {

  if(mod.workshareItem != ""){
    let result = await helper.CheckWorkItemStatus(mod.workshareItem, token)

  if(result == "success"){setWorkItemWorkshare(mod,"","Workshared! reload page to see results")}
  else if(result != "inprogress" && result != "pending"){setWorkItemWorkshare(mod,"","Failed to workshare! Contact VDC")}
  else{setWorkItemWorkshare(mod,mod.workshareItem,"Worksharing... ")}


  }

}

function setWebhook(){
  alert("You will be notified when a arch model is dropped into the arch folder!")

    helper.setWebhook(token,project.id,project.name)
}


  function setWorkItemWorkshare(mod: RevitModel,item:string, status:string){
    mod.workshareItem =item;
    mod.workshareItemStatus = status;
    
    const nextModels = models.map(mo =>{
      if(mod.createTime == mo.createTime && mod.name == mo.name){return mod}
      else{return mo}
    })

    setModels(nextModels)

  }



  const effectRan = useRef(false);

  useEffect(() => {

    if (!effectRan.current) {

      // helper.getPModels(token, project.name,project.id).then((models) => {
      helper.getModelsDataManagment(token, project.name, project.id).then((models) => {

        if (typeof models === "string") {
          setmodelLoadError(models)
        } else {
          setmodelLoadError("")

         
          setModels(models)
          setCreated(helper.populateCreatedModels(models))
          setEnableButton(false)

          models.forEach((model) => {
       
            if((model as any).extension.data.revitProjectVersion){
            setPossibleVersions([(model as any).extension.data.revitProjectVersion])
          }

           if(model.parentFolder.name !== "Non-Workshared_Models" && (model as any).extension.type == "versions:autodesk.bim360:C4RModel"){
              helper.getLevels(token, project.name, model.name).then((Levels) => {
                
                if (typeof Levels !== "string") {
                  if (model.parentFolder.name.includes("Arch")) {
                    setArchLevels(Levels)
                    setArchModel(model)
                  
                  }

                  if (model.parentFolder.name.includes("Struct")) {
                    
                    setStructLevels(Levels) 
                  }
                  if (model.parentFolder.name.includes("Elec")) {
                    setElecLevels(Levels)
                  }
                  if (model.parentFolder.name.includes("Mech")) {
                    setMechLevels(Levels)
                  }
                
                }
              })
            }


          })

        }

        //  helper.append_Meta_Data(token,models).then((models_combined)=>{
        // setModels(models_combined)
        // })

      })

    }
    return () => { effectRan.current = true };

  }, [])



  return (
    <>
      <br />
      <div className='ActionsHead'><a className='DetailsHeaders'>Models in ACC</a>

        <button disabled={enableButton}
          className=" bg-blue-200 text-black active:bg-blue-500 
      font-bold px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
          type="button"
          onClick={() => setShowModal(true)}
        >
         
          Create Models
        </button>
         {/* Temp button to test webhook creation*/}
{!ArchModel ?
         <button  disabled={enableButton}
          className=" bg-blue-200 text-black active:bg-blue-500 
      font-bold px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
          type="button"
          onClick={() =>  setWebhook()}
        >
         
          Notify me when Arch model is added
        </button>
        :null}

        </div>
      <br />

      {enableButton ? <><br /><br /><br /><div className=" DetailsTables flex items-center justify-center"> {modelLoadError ? <h3 className='text-red-600'> {modelLoadError}</h3> : <ClipLoader size={100}></ClipLoader>}</div></> :
        <table className=" DetailsTables table-auto border-separate border-spacing-2 border border-slate-400 ...">
          <thead>
            <tr key="header">
              <th className="bg-red-100 border text-left px-8 py-4">Name</th>
              <th className="bg-red-100 border text-left px-8 py-4">Status</th>
              <th className="bg-red-100 border text-left px-8 py-4">Parent Folder</th>
              <th className="bg-red-100 border text-left px-8 py-4">Created By</th>
              <th className="bg-red-100 border text-left px-8 py-4">Last Modified</th>
              <th className="bg-red-100 border text-left px-8 py-4">Revit Version</th>
              <th className="bg-red-100 border text-left px-8 py-4">Additional Commands</th>

            </tr>
          </thead>
          <tbody>
            {

              [...models, ...modelsLoading].map(model => {
                return (<>{model.name === "" ? <></> :
                <>{model.parentFolder.name === "Non-Workshared_Models"? null :
                  <tr key={model.id}>
                    <td>
                      {model.failed ? <>Failed to create</> :
                        <>{model.loading ? <>

                          <a>{model.name}</a>
                        </>
                          :
                          <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" target="_blank" rel="noopener noreferrer" href={"https://acc.autodesk.com/docs/files/projects/" + project.id + "?folderUrn=" + model.parentFolder.id}>{model.name}</a>
                        }</>}

                    </td>
                    <td>
                      {model.loading ? <Progress progress={model.progress}></Progress> : <>Created</>}
                    </td>
                    <td>{model.parentFolder.name}</td>
                    <td>{model.createUserName}</td>
                    <td>{(new Date(model.lastModifiedTime)).toLocaleDateString()}</td>
                    <td>{model.loading ? null : (model as any).extension.data.revitProjectVersion}</td>
                    <td>


                      { !model.loading && (model as any).extension.type != "versions:autodesk.bim360:C4RModel" ? 
                      <>
                      {model.workshareItem != undefined ? <a> {model.workshareItemStatus} {model.workshareItem != ""?<ClipLoader size={50}></ClipLoader>:null}</a>:
                      <button 
                          className=" bg-blue-200 text-black active:bg-blue-500 
                font-bold px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                          type="button"
                          onClick={()=>{WorkshareModel(model)}}
                        >
                          Workshare
                        </button>
                        }
                        </>
                        
                        :null}



                      {(model.parentFolder.name.includes("Struct") || model.parentFolder.name.includes("Mech") || model.parentFolder.name.includes("Elec")) && !model.loading && ArchLevels && ArchLevels.length > 0?
                        <>  
                        <Link to={"/AssignLevels"} state={{arch:ArchLevels,pec:getPECmdoels(model),model:model,archModel:ArchModel,project:project}}> 
                        <button disabled={enableButton}
                          className=" bg-blue-200 text-black active:bg-blue-500 
                font-bold px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                          type="button"


                        >
                          Levels
                        </button>
                        </Link>
                        
                        </>
                        : <></>
                      }
                    
                    </td>

                  </tr>
              }</>
                }</>);
              })
            }
          </tbody>
        </table>
      }



      {showModal ? (
        <>
          <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none backdrop-blur-sm">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-4 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
                  <h3 className="text-3xl font=semibold">Create Models</h3>
                  <button
                    className="bg-transparent border-0 text-black float-right"
                    onClick={() => setShowModal(false)}
                  >

                  </button>
                </div>
                <div className="relative p-6 flex-auto">
                  <form className="bg-gray-200 shadow-md rounded px-8 pt-6 pb-8 w-full">
                    <div >
                      <label className='label-text  text-sm font-medium text-gray-700 '>
                        <input checked={MechCheck} onClick={() => { setMechCheck(!MechCheck) }} defaultChecked={modelsCreated.Mech} disabled={modelsCreated.Mech} type='checkbox' id='MechanicalCheck' name='MechanicalCheck' />
                        <span> Mechanical {modelsCreated.Mech ? (<>(Already created)</>) : null}</span>
                      </label>
                    </div>
                    <div >
                      <label className='label-text  text-sm font-medium text-gray-700 '>
                        <input checked={ElecCheck} onClick={() => { setElecCheck(!ElecCheck) }} defaultChecked={modelsCreated.Elec} disabled={modelsCreated.Elec} type='checkbox' id='ElectricalCheck' name='ElectricalCheck' />
                        <span> Electrical {modelsCreated.Elec ? (<>(Already created)</>) : null}</span>
                      </label>
                      <div >
                        <label className='label-text  text-sm font-medium text-gray-700 '>
                          <input checked={StructCheck} onClick={() => { setStructCheck(!StructCheck) }} defaultChecked={modelsCreated.Struct} disabled={modelsCreated.Struct} type='checkbox' id='StructuralCheck' name='StructuralCheck' />
                          <span> Structural {modelsCreated.Struct ? (<>(Already created)</>) : null}</span>
                        </label>
                      </div>

                      <div >
                        <br></br>
                        <label className='label-text  text-sm font-medium text-gray-700 '> Model Version</label>
                      </div>
                      <div>
                        <select defaultValue={version} onChange={(e) => { setversion(e.target.value) }} name="revitVersions" id="revitVersions">
                          {possibleVersions.map((vers) => { return (<option value={vers}>{vers}</option>) })}

                        </select>
                      </div>

                    </div>
                  </form>
                </div>
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                  <button
                    className="text-white bg-green-500 active:bg-yellow-700 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                    onClick={creationRequested}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}


   

    </>
  )
}


let b = {"jsonapi":{"version":"1.0"},"data":{"type":"items","id":"urn:adsk.wipprod:dm.lineage:0v2uybyMT7ek3AyQSVgzmw","relationships":{"parent":{"data":{"type":"folders","id":"urn:adsk.wipprod:fs.folder:co.54vcYarJS9SizprVD4NDnQ"}}}}} 
